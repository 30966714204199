import * as React from 'react';
import AboutLabel from '../../images/about-label.svg';
import AboutPolygon from '../../images/about-polygon.svg';
import WorksLabel from '../../images/works-label.svg';
import WorksPolygon from '../../images/works-polygon.svg';
import ContactPolygon from '../../images/contact-polygon.svg';
import ContactLabel from '../../images/contact-label.svg';
import Github from '../../images/github.svg';
import Twitter from '../../images/twitter-icon.svg';
import LinkedIn from '../../images/linkedin-icon.svg';
import classnames from 'classnames';

interface IProps {
    scrollTop: Number
}


class navItems extends React.Component<IProps> {

    handleClick = e => {
        e.persist();
        console.log(e.target.parentElement.parentElement.id)
        if (e.target.innerHTML === "about" || e.target.parentElement.parentElement.id === "about") {
            window.scrollTo({ top: 760, behavior: 'smooth' })
        } else if (e.target.innerHTML === "works" || e.target.parentElement.parentElement.id === "works") {
            window.scrollTo({ top: 1500, behavior: 'smooth' })
        } else if (e.target.innerHTML === "contact" || e.target.parentElement.parentElement.id === "contact") {
            window.scrollTo({ top: 3200, behavior: 'smooth' })
        } else {
            console.log("error")
        }
    }

    render() {
        const { scrollTop } = this.props

        let positionAbout, positionWorks, positionContact;
        if (scrollTop > 300 && scrollTop < 900) positionAbout = true;
        if (scrollTop >= 900 && scrollTop < 2850) positionWorks = true;
        if (scrollTop >= 2850) positionContact = true;


        return (
            <React.Fragment>
                <div className="nav-item-group" onClick={this.handleClick}>
                    <div id="about" className="nav-item-focused">
                        <div className={classnames("nav-item-polygon about-polygon", { "rotate fade-in": positionAbout })}>
                            {positionAbout && <AboutPolygon />}
                        </div>
                        <div className={classnames("nav-item-label", { "fade-in": positionAbout })} >
                            {positionAbout && <AboutLabel />}
                        </div>
                    </div>
                    <div className="nav-item">
                        {!positionAbout && <p>about</p>}
                    </div>
                </div>
                <div className="nav-item-group" onClick={this.handleClick}>
                    <div id="works" className="nav-item-focused">
                        <div className={classnames("nav-item-polygon works-polygon", { "rotate fade-in": positionWorks })}>
                            {positionWorks && <WorksPolygon />}
                        </div>
                        <div className={classnames("nav-item-label", { "fade-in": positionWorks })} >
                            {positionWorks && <WorksLabel />}
                        </div>
                    </div>
                    <div className="nav-item">
                        {!positionWorks && <p>works</p>}
                    </div>
                </div>
                <div className="nav-item-group" onClick={this.handleClick}>
                    <div id="contact" className="nav-item-focused">
                        <div className={classnames("nav-item-polygon contact-polygon", { "rotate fade-in": positionContact })}>
                            {positionContact && <ContactPolygon />}
                        </div>
                        <div className={classnames("nav-item-label", { "fade-in": positionContact })} >
                            {positionContact && <ContactLabel />}
                        </div>
                    </div>
                    <div className="nav-item">
                        {!positionContact && <p>contact</p>}
                    </div>
                </div>
                <div className="text-center" style={{ position: `fixed`, bottom: `2%`, left: `3%` }}>
                    <a href="https://github.com/leonvdb" target="_blank"><Github className="mr-4 social-icon" style={{ width: `30px`, height: `30px` }} /></a>
                    <a href="https://www.linkedin.com/in/leon-vdb/" target="_blank"><LinkedIn className="mr-4 social-icon" /></a>
                    <a href="https://twitter.com/VdbLeon" target="_blank"><Twitter className="social-icon" /></a>
                    <p className="mt-2">leon.vondemberge@gmail.com</p>
                    <p style={{ marginTop: `-15px` }}>+972586000965</p>
                </div>
            </React.Fragment>

        )

    }

}

export default navItems;